@import "styles/_variables";

.navigation {
  @include get-text-color($light-text-color);
  align-items: center;
  display: flex;
  font-weight: map-get($font-weights, "bold");
  font-size: map-get($font-sizes, "4");
  justify-content: flex-end;
  margin: 0 auto;
  max-width: calc(
    $max-content-width - map-get($spacing-sizes, "2") -
      map-get($spacing-sizes, "2")
  );
  padding: map-get($spacing-sizes, "2") map-get($spacing-sizes, "3");
  width: calc(
    100% - map-get($spacing-sizes, "3") - map-get($spacing-sizes, "3")
  );
  ul {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (min-width: $mobile-large) {
  .navigation {
    padding: map-get($spacing-sizes, "2") map-get($spacing-sizes, "1");
    max-width: calc(
      $max-content-width - map-get($spacing-sizes, "1") -
        map-get($spacing-sizes, "1")
    );
    width: calc(
      100% - map-get($spacing-sizes, "1") - map-get($spacing-sizes, "1")
    );
  }
}
