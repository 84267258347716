@import "styles/_variables";

.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100vh;
  justify-content: flex-start;
  width: 100%;
}

.main {
  background-color: $background-color;
  flex-grow: 1;
  overflow: auto;
  width: 100%;
}

.mainContent {
  @include get-text-color($background-color);
  margin: 0 auto;
  width: 100%;
}

.header {
  background-color: $primary-color;
  position: sticky;
  top: 0;
  width: 100%;
}

.footer {
  background-color: $background-color;
  box-shadow: 2px 2px 4px 2px rgba(29, 29, 29, 0.3);
  @include get-text-color($background-color);
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 3;
}
