@import "styles/_variables";

.activeNavbarLink {
  margin-left: map-get($spacing-sizes, "3");
  a {
    @include get-text-color($light-text-color);
    font-size: map-get($font-sizes, "4");
    text-decoration: none;
    text-transform: uppercase;
  }
}

.activeNavbarLink:first-child,
.navbarLink:first-child {
  margin-left: 0;
}

.navbarLink {
  @include get-text-color($light-text-color);
  cursor: pointer;
  font-size: map-get($font-sizes, "4");
  margin-left: map-get($spacing-sizes, "3");
  text-decoration: none;
  text-transform: uppercase;
  a {
    @include get-text-color($primary-color);
    cursor: pointer;
    font-size: map-get($font-sizes, "4");
    text-decoration: underline;
    text-transform: uppercase;
  }
}

@media only screen and (min-width: $mobile-large) {
  .activeNavbarLink,
  .navbarLink {
    margin-left: map-get($font-sizes, "3");
  }
  .activeNavbarLink:first-child,
  .navbarLink:first-child {
    margin-left: 0;
  }
}

@media only screen and (min-width: $tablet) {
  .activeNavbarLink,
  .navbarLink {
    margin-left: map-get($font-sizes, "2");
  }
  .activeNavbarLink:first-child,
  .navbarLink:first-child {
    margin-left: 0;
  }
}
