@import "styles/_variables";

.buttonsContainer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: map-get($spacing-sizes, "4");
}

.close {
  cursor: pointer;
  margin-top: map-get($spacing-sizes, "6");
  height: map-get($spacing-sizes, "2");
  width: map-get($spacing-sizes, "2");
  position: relative;
}

.contentContainer {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  a {
    color: $dark-text-color;
    cursor: pointer;
    font-weight: map-get($font-weights, "bold");
    text-decoration: underline;
  }
}

.headerContainer {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.buttonsContainer .button {
  background-color: $accent-blue;
  cursor: pointer;
  @include get-text-color($accent-blue);
  font-weight: map-get($font-weights, "bold");
  text-transform: uppercase;
  margin: 0 !important;
  margin-right: map-get($spacing-sizes, "4") !important;
  padding: map-get($spacing-sizes, "5") map-get($spacing-sizes, "4");
  width: 80px;
  text-align: center;
}

.button:hover {
  background-color: $primary-color;
  @include get-text-color($primary-color);
}

.button:last-child {
  margin-right: 0 !important;
}

.container {
  margin: 0 auto;
  max-width: $max-content-width;
  padding: map-get($spacing-sizes, "4") map-get($spacing-sizes, "3");
  position: relative;
  max-width: calc(
    $max-content-width - map-get($spacing-sizes, "1") -
      map-get($spacing-sizes, "1")
  );
  width: calc(
    100% - map-get($spacing-sizes, "3") - map-get($spacing-sizes, "3")
  );
  z-index: 3;
}

@media only screen and (min-width: 768px) {
  .buttonsContainer {
    margin-bottom: map-get($spacing-sizes, "4");
    margin-left: map-get($spacing-sizes, "3");
  }
  .contentContainer {
    flex-direction: row;
  }
}
